import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import PersonalSpouseDependent from "./PersonalSpouseDependent";
import { useId } from "../../../context/IdContext";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import { CallCenterContext } from "../../../context/CallCenterContext";
import useFetchUserDetails from "../../../hooks/useFetchUserDetail";

const ContactPersonalSubHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const handleNavigation = (e) => {
  //   e.preventDefault();
  //   navigate("/phone/call");
  // };

  // // const isDetailsPage = location.pathname.startsWith('/contact/personal/details/');
  // const isPersonalPage = location.pathname.includes('/contact/personal/details/');
  // const isSpousePage = location.pathname.includes('/contact/spouse/details/');
  // const isDependentPage = location.pathname.includes('/contact/dependent/details/');
  const { id } = useParams();
  const { setId } = useId();

  useEffect(() => {
    if (id) {
      setId(id);
    }
  }, [id, setId]);

  const personalPath = `/contact/personal/details/${id}`;
  const spousePath = `/contact/spouse/details/${id}`;
  const dependentPath = `/contact/dependent/details/${id}`;
  const isPersonalPage = location.pathname === personalPath;
  const isSpousePage = location.pathname === spousePath;
  const isDependentPage = location.pathname === dependentPath;
  const { jsonApi } = useApis();
  // const [data, setData] = useState({});
  const { makeCall } = useContext(CallCenterContext);

  const handleNavigationProfile = (path) => {
    if (id) {
      navigate(path);
    }
  };

  const { data } = useFetchUserDetails(id);

  const handlePhoneClick = () => {
    // navigate("/phone/call");
    if (data?.phone && data?.phone[0]?.value) {
      const phoneNumber = data?.phone[0].value;
      makeCall(phoneNumber, data._id, data._id);
    } else {
      // alert("Phone number not available.");
    }
  };
  return (
    <div
      className="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <Link
        to="/contact/personal/add/detail"
        className="btn waves-effect waves-light btn-rounded btn-sm btn-outline-primary mb-2"
      >
        + Add Consumer Contact
      </Link>

      <div className="row">
        <ul className="nav">
          <li className="nav-item">
            <Link
              to="/contact/lead/client"
              className={`nav-link ${
                location.pathname === "/contact/lead/client" ? "active" : ""
              } `}
            >
              Client
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact/lead/prospects"
              className={`nav-link ${
                location.pathname === "/contact/lead/prospects" ? "active" : ""
              } `}
            >
              Prospects
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact/lead/campaigns/list"
              className={`nav-link ${
                location.pathname === "/contact/lead/campaigns/list"
                  ? "active"
                  : ""
              } `}
            >
              Campaigns
            </Link>
          </li>

          <li>
            <span className="separator">|</span>
          </li>

          <li className="nav-item">
            <Link
              to="/contact/personal/all"
              className={`nav-link ${
                location.pathname === "/contact/personal/all" ? "active" : ""
              } `}
            >
              All
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/contact/personal/favorites"
              className={`nav-link ${
                location.pathname === "/contact/personal/favorites"
                  ? "active"
                  : ""
              } `}
            >
              Favorites
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Wholesalers</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Vendors</Link>
          </li>
          <li>
            <span className="separator">|</span>
          </li>

          <li className="nav-item">
            <Link
              to="#"
              className="nav-link"
              onClick={(e) => {
                if (!id) {
                  e.preventDefault();
                } else {
                  window.location.href = `mailto:${data?.email[0]?.value}`;
                }
              }}
              style={{
                cursor: data?.email && data?.email[0]?.value ? "pointer" : "",
              }}
              title={
                data?.email && data?.email[0]?.value
                  ? "Click to mail"
                  : "Email not available"
              }
            >
              Email
            </Link>
          </li>

          <li className="nav-item" style={{ position: "relative" }}>
            <Link
              className="nav-link"
              to={id ? "/phone/call" : "#"}
              onClick={(e) => {
                if (!id) {
                  e.preventDefault();
                } else {
                  // navigate("/phone/call");
                  handlePhoneClick();
                }
              }}
              style={{
                cursor: data?.phone && data?.phone[0]?.value ? "pointer" : "",
              }}
              title={
                data?.phone && data?.phone[0]?.value
                  ? "Click to call"
                  : "Phone number not available"
              }
            >
              Phone Call
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to={id ? `/call/history/${id}` : "#"}
              className="nav-link"
              href="#"
            >
              Call History
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link">Contact Summary</Link>
          </li>

          <li className="nav-item">
            <Link
              to={id ? `/contact/list/tasks/${id}` : "#"}
              className="nav-link"
              onClick={(e) => !id && e.preventDefault()}
            >
              Task
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to={id ? `/contact/list/notes/${id}` : "#"}
              className="nav-link"
              onClick={(e) => !id && e.preventDefault()}
            >
              Notes
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to={id ? `/contact/event/calendar/${id}` : "#"}
              className="nav-link"
              onClick={(e) => !id && e.preventDefault()}
            >
              Calendar
            </Link>
          </li>
        </ul>
        <ul className="nav">
          <li className="nav-item">
            <Link
              to={personalPath}
              className={`nav-link ${isPersonalPage ? "active" : ""}`}
              tabIndex={isPersonalPage ? 0 : -1}
              onClick={(e) => {
                if (!id) {
                  e.preventDefault();
                } else {
                  handleNavigationProfile(personalPath);
                }
              }}
            >
              Contact Profile
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={spousePath}
              className={`nav-link ${isSpousePage ? "active" : ""}`}
              tabIndex={isSpousePage ? 0 : -1}
              onClick={(e) => {
                if (!id) {
                  e.preventDefault();
                } else {
                  handleNavigationProfile(spousePath);
                }
              }}
            >
              Spouse Profile
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={dependentPath}
              className={`nav-link ${isDependentPage ? "active" : ""}`}
              tabIndex={isDependentPage ? 0 : -1}
              onClick={(e) => {
                if (!id) {
                  e.preventDefault();
                } else {
                  handleNavigationProfile(dependentPath);
                }
              }}
            >
              Dependent Profile
            </Link>
          </li>

          <li>
            <span className="separator">|</span>
          </li>
          <li className="nav-item">
            <Link className="nav-link ">Sale Summary</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Sale Opportunity</Link>
          </li>

          <li>
            <span className="separator">|</span>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Files</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Text</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Chat</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">US Postal Mail</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Schedule Event</Link>
          </li>
        </ul>

        {id ? (
          <ul className="nav">
            <li className="nav-item">
              {console.log(location.pathname)}
              <Link
                to={`contact/quote/Medicare/${data?._id}`}
                className={`nav-link ${
                  location.pathname.startsWith("/contact/quote/Medicare")
                    ? "active"
                    : ""
                }`}
              >
                Medicare
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`contact/quote/Life Insurance/${data?._id}`}
                className={`nav-link ${
                  location.pathname.startsWith("/contact/quote/Life Insurance")
                    ? "active"
                    : ""
                }`}
              >
                Life Insurance
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`contact/quote/Auto Insurance/${data?._id}`}
                className={`nav-link ${
                  location.pathname.startsWith("/contact/quote/Auto Insurance")
                    ? "active"
                    : ""
                }`}
              >
                Auto Insurance
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`contact/quote/Home Insurance/${data?._id}`}
                className={`nav-link ${
                  location.pathname.startsWith("/contact/quote/Home Insurance")
                    ? "active"
                    : ""
                }`}
              >
                Homeowners Insurance
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`contact/quote/Solar Energy/${data?._id}`}
                className="nav-link"
              >
                Solar Energy
              </Link>
            </li>
            <li></li>
          </ul>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ContactPersonalSubHeader;
