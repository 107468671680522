import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import moment from "moment";

import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import { Link, Outlet, useNavigate, useSearchParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
  validateName,
  validateEmail,
  validateZipCode,
  validateSuiteNumber,
  validateSocialSecurity,
  validateNumber,
  validateMedicareNumber,
  validatePercentOwn,
  validateURL,
} from "../../validation/Validation";
import { PhoneInput } from "react-international-phone";
import DatePicker from "react-multi-date-picker";
import "../../LeadData/DatePickerStyle.css";
import DragsAndDrop from "../../../image/DragsAndDrop";
import { useFormContext } from "./FormContext";
import "./ProgessButton.css";
import { Context } from "../../../context/Context";

const AddPersonalContactForm = () => {
  document.title = "Contact - Digital Call Center";
  const { jsonApi, mediaApi } = useApis();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [dob, setDob] = useState(null);
  const [anniversary, setAnniversary] = useState(null);
  const [partADate, setPartADate] = useState(null);
  const [partBDate, setPartBDate] = useState(null);
  const [partCDate, setPartCDate] = useState(null);
  const { formContextData, setFormContextData } = useFormContext();
  const [campaignsList, setCampaignsList] = useState([]);
  const { userData } = useContext(Context);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({});
  const typeValue = watch("leadType");
  const {
    fields: personalFieldsPhone,
    append: appendPersonalPhone,
    remove: removePersonalPhone,
  } = useFieldArray({
    control,
    name: "phone",
  });

  const {
    fields: workFieldsPhone,
    append: appendWorkPhone,
    remove: removeWorkPhone,
  } = useFieldArray({
    control,
    name: "workPhone",
  });

  const {
    fields: personalFieldsEmail,
    append: appendPersonalEmail,
    remove: removePersonalEmail,
  } = useFieldArray({
    control,
    name: "email",
  });

  const {
    fields: workFieldsEmail,
    append: appendWorkEmail,
    remove: removeWorkEmail,
  } = useFieldArray({
    control,
    name: "workEmail",
  });

  const init = async () => {
    setFormContextData({});
    const res = await jsonApi(
      appConstant.getSingleUserDetail,
      { id: searchParams.get("id") },
      {},
      false
    );
    if (!res?.error) {
      setSearchParams({
        id: res.data.data._id,
      });
      setData(res.data.data);
      console.log(res.data.data.notes);

      if (res.data.data?.notes.length === 0) {
        const currentDateTime = moment().format("DD/MM/YYYY, hh:mm A");
        const initialContent = `${currentDateTime}  ${userData.name} `;

        setFormContextData({
          leadSource: res.data.data.leadSource,
          typeOfLead: res.data.data.typeOfLead,
          saleStage: res.data.data.saleStage,
          notes: initialContent,
        });
      } else {
        const foundNote = res.data.data?.notes.find(
          (note) => note.noteName === "Added"
        );

        if (foundNote) {
          setFormContextData({
            leadSource: res.data.data.leadSource,
            typeOfLead: res.data.data.typeOfLead,
            saleStage: res.data.data.saleStage,
            notes: foundNote.noteDescription,
          });
        }
      }

      populateForm(res.data.data);
    }
  };

  useEffect(() => {
    init();
  }, [searchParams.get("id")]);

  const onSubmit = async (data) => {
    const dataWithUserId = {
      ...data,
      userId: searchParams.get("id"),
      type:
        data.leadType === "client" ||
        data.leadType === "prospects" ||
        data.leadType === "campaigns"
          ? "Lead"
          : "Personal",
      ...formContextData,
    };

    const response = await jsonApi(
      appConstant.updateUserSpouse,
      null,
      dataWithUserId,
      false,
      true
    );

    if (!response.error) {
      // setFormContextData({});
      // reset();
      // navigate("/contact/personal");
      // setIsSaved(true);
    } else {
      console.error("Unexpected response:", response);
    }
  };

  const handleNameChange = (e) => {
    const fullName = data.fullName.trim();
    const nameParts = fullName.split(" ");

    if (nameParts.length > 1) {
      setValue("firstName", nameParts[0]);
      setValue("lastName", nameParts[nameParts.length - 1]);
      setValue(
        "middleName",
        nameParts.length > 2 ? nameParts.slice(1, -1).join(" ") : ""
      );
      console.log(nameParts[0]);
    } else {
      setValue("firstName", nameParts[0]);
      setValue("middleName", "");
      setValue("lastName", "");
    }
  };

  const populateForm = (data) => {
    if (data) {
      Object.keys(data).forEach((key) => {
        setValue(`${key}`, data[key]);
      });
      if (data.phone.length === 0) {
        setValue("phone", [{ value: "", type: "Personal" }]);
      }
      if (data.email.length === 0) {
        setValue("email", [{ value: "", type: "Personal" }]);
      }
      if (data.workPhone.length === 0) {
        setValue("workPhone", [{ value: "", type: "Work" }]);
      }
      if (data.workEmail.length === 0) {
        setValue("workEmail", [{ value: "", type: "Work" }]);
      }
    }
  };

  useEffect(() => {
    const calculateAge = (date) => {
      if (!date) return "";
      var today = new Date();
      var birthDate = new Date(date);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };
    const age = calculateAge(dob);
    setError("");
    setValue("age", age || "");

    const yearOfMarriage = calculateAge(anniversary);
    setValue("yearOfMarriage", yearOfMarriage || "");
  }, [dob, anniversary, setValue]);

  const handleDateChange = (date, fieldName) => {
    if (date instanceof Date && !isNaN(date)) {
      switch (fieldName) {
        case "partA":
          setPartADate(date);
          break;
        case "partB":
          setPartBDate(date);
          break;
        case "partC":
          setPartCDate(date);
          break;
        case "dob":
          setDob(date);
          break;
        default:
          break;
      }
      setValue("date", date.toISOString(), { shouldValidate: true });
      setError(""); // Clear error if date is valid
    } else {
      setError("Invalid date format. Please select a valid date.");
    }
  };

  const hasError = Object.keys(errors).length > 0;
  const getGeneralErrorMessage = () => {
    if (hasError) {
      return "Enter fill the necessary details!";
    }
    return "";
  };

  const onImageChange = async (x, id) => {
    const formData = new FormData();
    formData.append("logo", x);
    const dd = await mediaApi(
      appConstant.updateContactProfile,
      { id },
      formData,
      true,
      true
    );
  };

  const fetchCampaignsListData = async () => {
    const campaignsListData = await jsonApi(
      appConstant.getCampaignsList,
      {},
      null,
      null
    );
    if (!campaignsListData.error) {
      setCampaignsList(campaignsListData?.data?.data);
    }
  };

  useEffect(() => {
    fetchCampaignsListData();
  }, []);

  useEffect(() => {
    if (typeValue === "campaigns") {
      const leadList = watch("leadList");
      if (!leadList && !(campaignsList.length === 0)) {
        setValue("leadList", data.leadList);
      }
    } else {
      setValue("leadList", "");
    }
  }, [typeValue, campaignsList]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <div className="col"> */}
            {/* <div className="card"> */}
            <div className="card-body custom-field-button">
              <div className="row">
                <div className="col-sm-6 col-md-12">
                  <div className="form-group d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <label
                        htmlFor="leadType"
                        className="mr-2 mb-0"
                        style={{ width: "150px" }}
                      >
                        Current Lead Type
                      </label>
                      <select
                        id="leadType"
                        className={`form-control-contact black-border ${
                          errors.leadType ? "border border-danger" : ""
                        }`}
                        {...register("leadType", {})}
                        style={{ width: "200px" }}
                      >
                        <option value="">Select</option>
                        <option value="campaigns">Campaigns</option>
                        <option value="client">Client</option>
                        <option value="prospects">Prospects</option>
                      </select>
                    </div>
                    {errors.leadType && (
                      <div className="text-danger">
                        {errors.leadType.message}
                      </div>
                    )}
                    {typeValue === "campaigns" ? (
                      <div className="mx-2">
                        <div className="d-flex align-items-center">
                          <label
                            htmlFor="leadList"
                            className="mr-2 mb-0"
                            style={{ width: "150px" }}
                          >
                            Select Campaigns
                          </label>
                          <select
                            id="leadList"
                            required={true}
                            className={`form-control-contact black-border ${
                              errors.leadList ? "border border-danger" : ""
                            }`}
                            {...register("leadList", {})}
                            style={{ width: "200px" }}
                          >
                            <option value="">Select</option>
                            {campaignsList.map((item) => (
                              <option value={item._id}>{item.listName}</option>
                            ))}
                          </select>
                        </div>
                        {errors.leadList && (
                          <div className="text-danger">
                            {errors.leadList.message}
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}

                    {/* <div className="col-sm-12 col-md-6"> */}
                    <div className="ml-auto">
                      <button
                        type="submit"
                        className="btn_green btn_black_text mx-2"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-12 align-self-center">
                  {hasError && (
                    <div className="alert alert-danger text-center">
                      {getGeneralErrorMessage()}
                    </div>
                  )}
                  <div className="row">
                    <div class="col-lg-12">
                      <div className="row">
                        <div className="col-auto">
                          <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                            Contact Details
                          </h4>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group d-flex align-items-center mb-3">
                            <label
                              htmlFor="fullName"
                              className="mr-2"
                              style={{ width: "150px" }}
                            >
                              Name:
                            </label>
                            <input
                              type="text"
                              maxLength={256}
                              required
                              className={`form-control-contact black-border ${
                                errors.fullName ? "border border-danger" : ""
                              }`}
                              id="fullName"
                              onBlur={handleNameChange}
                              //placeholder="John Luther"
                              {...register("fullName", {
                                required: false,
                                validate: (value) =>
                                  value
                                    ? validateName(value, "fullName")
                                    : null,
                              })}
                            />

                            {errors.name && (
                              <p className="text-danger">
                                {errors.name.message}
                              </p>
                            )}
                          </div>

                          <input
                            type="hidden"
                            name="firstName"
                            {...register("firstName")}
                          />
                          <input
                            type="hidden"
                            name="middleName"
                            {...register("middleName")}
                          />
                          <input
                            type="hidden"
                            name="lastName"
                            {...register("lastName")}
                          />

                          <div className="form-group d-flex align-items-start mb-3">
                            <label
                              htmlFor="cellNo"
                              className="mr-2"
                              style={{ width: "150px" }}
                            >
                              Mobile:
                            </label>

                            <div className="d-flex flex-column flex-grow-1">
                              {personalFieldsPhone.map((item, index) => (
                                <div
                                  key={item.id}
                                  className="d-flex align-items-center mb-1"
                                  
                                >
                                  <Controller
                                    name={`phone.${index}.value`}
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <PhoneInput
                                          preferredCountries={"us"}
                                          value={field.value}
                                          // inputProps={{ required: true }}
                                          onChange={field.onChange}
                                          onBlur={field.onBlur}
                                          disableDialCodeAndPrefix={true}
                                          width={100}
                                          disableFocusAfterCountrySelect={false}
                                          hideDropdown={true}
                                          // disableCountryGuess={true}
                                          className="black-border"
                                          style={{ width: "150px" }}
                                        />
                                        {index === 0 ? (
                                          <button
                                            type="button"
                                            className="btn btn-outline-primary btn-circle ml-2"
                                            onClick={() =>
                                              appendPersonalPhone({
                                                value: "",
                                                type: "Personal",
                                              })
                                            }
                                          >
                                            <i className="fa fa-plus"></i>
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn btn-outline-primary btn-circle ml-2"
                                            onClick={() =>
                                              removePersonalPhone(index)
                                            }
                                            style={{ color: "red" }}
                                          >
                                            <i className="fa fa-minus"></i>
                                          </button>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              ))}
                            </div>

                            {/* <div className="d-flex justify-content- mb-3 ">
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-circle"
                                onClick={() =>
                                  appendPersonalPhone({
                                    value: "",
                                    type: "Personal",
                                  })
                                }
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div> */}
                          </div>

                          <div className="form-group d-flex align-items-start mb-3">
                            <label
                              htmlFor="personalEmail"
                              className="mr-2"
                              style={{ width: "150px" }}
                            >
                              Email:
                            </label>

                            <div className="d-flex flex-column flex-grow-1">
                              {personalFieldsEmail.map((item, index) => (
                                <>
                                  <div key={item.id || index} className="d-flex">
                                    <input
                                      type="email"
                                      className={`form-control-contact black-border mb-1${
                                        errors.email
                                          ? "border border-danger"
                                          : ""
                                      }`}
                                      // id="email"
                                      id={`email-${index}`}
                                      maxLength={256}
                                      {...register(`email.${index}.value`, {
                                        validate: validateEmail,
                                      })}
                                    />
                                    {index === 0 ? (
                                      <button
                                        type="button"
                                        className="btn btn-outline-primary btn-circle ml-2"
                                        onClick={() =>
                                          appendPersonalEmail({
                                            value: "",
                                            type: "Personal",
                                          })
                                        }
                                      >
                                        <i className="fa fa-plus"></i>
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-outline-primary btn-circle ml-2"
                                        onClick={() =>
                                          removePersonalEmail(index)
                                        }
                                        style={{ color: "red" }}
                                      >
                                        <i className="fa fa-minus"></i>
                                      </button>
                                    )}
                                  </div>
                                  {errors?.email && (
                                    <p className="text-danger">
                                      {errors?.email[index]?.value.message}
                                    </p>
                                  )}
                                </>
                              ))}
                            </div>

                            {/* <div className="d-flex justify-content-center align-items-center pe-4">
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-circle"
                                data-toggle="modal"
                                data-target="#primaryContactModal"
                                onClick={() => {
                                  appendPersonalEmail({
                                    value: "",
                                    type: "Personal",
                                  });
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div> */}
                          </div>

                          <div className="form-group d-flex align-items-center mb-3">
                            <label
                              htmlFor="primaryResidenceYearsThere"
                              className="mr-2"
                              style={{ width: "150px" }}
                            >
                              Years There:
                            </label>

                            <input
                              id="yearsThere"
                              type="text"
                              maxLength={2}
                              className={`form-control-contact black-border ${
                                errors.primaryResidenceYearsThere
                                  ? "border border-danger"
                                  : ""
                              }`}
                              {...register("primaryResidenceYearsThere", {
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: "Years There must be a number",
                                },
                              })}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              style={{ width: "50px" }}
                            />
                            {errors.primaryResidenceYearsThere && (
                              <p className="text-danger">
                                {errors.primaryResidenceYearsThere?.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group d-flex align-items-center mb-3">
                            <label
                              htmlFor="primaryStreetAddress"
                              className="mr-2"
                              style={{ width: "150px" }}
                            >
                              Street Add.:
                            </label>
                            <input
                              type="text"
                              className={`form-control-contact black-border ${
                                errors.primaryStreetAddress
                                  ? "border border-danger"
                                  : ""
                              }`}
                              id="streetAddress"
                              maxLength={256}
                              //placeholder="1435 Cedarwood Drive, San Mateo, CA, 89521"
                              {...register("primaryStreetAddress", {})}
                            />

                            {errors.primaryStreetAddress && (
                              <p className="text-danger">
                                {errors.primaryStreetAddress.message}
                              </p>
                            )}
                          </div>

                          <div className="form-group d-flex align-items-center mb-3">
                            <label
                              htmlFor="primaryCity"
                              className="mr-2"
                              style={{ width: "150px" }}
                            >
                              City:
                            </label>
                            <input
                              type="text"
                              className={`form-control-contact black-border ${
                                errors.primaryCity ? "border border-danger" : ""
                              }`}
                              //placeholder="Los Angeles"
                              id="primaryCity"
                              maxLength={256}
                              {...register("primaryCity", {
                                validate: (value) =>
                                  value
                                    ? validateName(value, "primaryCity")
                                    : null,
                              })}
                            />

                            {errors.primaryCity && (
                              <p className="text-danger">
                                {errors.primaryCity.message}
                              </p>
                            )}
                          </div>

                          <div className="form-group d-flex align-items-center mb-3">
                            <label
                              htmlFor="primarySuiteNo"
                              className="mr-2"
                              style={{ width: "150px" }}
                            >
                              Suite #:
                            </label>
                            <input
                              type="text"
                              className={`form-control-contact black-border ${
                                errors.primarySuiteNo
                                  ? "border border-danger"
                                  : ""
                              }`}
                              id="primarySuiteNo"
                              maxLength={256}
                              //placeholder="STE 200"
                              {...register("primarySuiteNo", {
                                validate: validateSuiteNumber,
                              })}
                            />

                            {errors.primarySuiteNo && (
                              <p className="text-danger">
                                {errors.primarySuiteNo.message}
                              </p>
                            )}
                          </div>

                          <div className="form-group d-flex align-items-center mb-3">
                            <label
                              htmlFor="primaryState"
                              className="mr-2"
                              style={{ width: "150px" }}
                            >
                              State:
                            </label>
                            <input
                              type="text"
                              className={`form-control-contact black-border ${
                                errors.primaryState
                                  ? "border border-danger"
                                  : ""
                              }`}
                              //placeholder="California"
                              maxLength={256}
                              id="state"
                              {...register("primaryState", {
                                validate: (value) =>
                                  value
                                    ? validateName(value, "primaryState")
                                    : null,
                              })}
                            />
                            {errors.primaryState && (
                              <p className="text-danger">
                                {errors.primaryState.message}
                              </p>
                            )}
                          </div>

                          <div className="form-group d-flex align-items-center mb-3">
                            <label
                              htmlFor="primaryZip"
                              className="mr-2"
                              style={{ width: "150px" }}
                            >
                              Zip:
                            </label>
                            <input
                              type="text"
                              className={`form-control-contact black-border ${
                                errors.primaryZip ? "border border-danger" : ""
                              }`}
                              id="primaryZip"
                              maxLength={5}
                              //placeholder="78458"
                              {...register("primaryZip", {
                                validate: validateZipCode,
                              })}
                            />
                            {errors.primaryZip && (
                              <p className="text-danger">
                                {errors.primaryZip.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="d-flex align-items-center mx-4">
                            <label
                              htmlFor="clientRank"
                              className="mr-2"
                              // style={{ width: "150px" }}
                            >
                              Client Rank:
                            </label>

                            <select
                              id="clientRank"
                              className={`form-control-contact black-border ${
                                errors.clientRank ? "border border-danger" : ""
                              }`}
                              {...register("clientRank", {})}
                            >
                              <option value="">Select</option>
                              <option value="Platinum">Platinum</option>
                              <option value="Gold">Gold</option>
                              <option value="Special">Special</option>
                              <option value="Former">Former</option>
                            </select>
                          </div>

                          <div
                            className="ml-auto mt-4"
                            style={{ width: "150px" }}
                          >
                            <DragsAndDrop
                              imgKey={data?.profileImage}
                              // className={"admin-profile-round"}
                              className="rounded-circle profile-image-spouse-consumer"
                              style={{ width: "100px", height: "100px" }}
                              heading="Upload Image"
                              inputName="Image"
                              aspect={1 / 1}
                              uploadFile={(x) => {
                                onImageChange(x, data._id);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body custom-field-button">
                <div className="row">
                  <div className="col-12 align-self-center">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-auto">
                            <h4 className="page-title text-truncate text-dark font-weight-medium">
                              Work Info
                            </h4>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            {/* <div className="col-lg-4"> */}
                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="companyName"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Company Name:
                              </label>
                              <input
                                type="text"
                                className={`form-control-contact black-border ${
                                  errors.companyName
                                    ? "border border-danger"
                                    : ""
                                }`}
                                id="companyName"
                                maxLength={256}
                                //placeholder="Enter company name"
                                {...register("companyName", {
                                  validate: (value) =>
                                    value ? validateName(value, "name") : null,
                                })}
                              />
                              {errors.companyName && (
                                <p className="text-danger">
                                  {errors.companyName?.message}
                                </p>
                              )}
                            </div>
                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="position"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Position:
                              </label>
                              <input
                                type="text"
                                className={`form-control-contact black-border ${
                                  errors.companyPosition
                                    ? "border border-danger"
                                    : ""
                                }`}
                                id="position"
                                maxLength={256}
                                //placeholder="Manager"
                                {...register("companyPosition", {})}
                              />
                              {errors.companyPosition && (
                                <p className="text-danger">
                                  {errors.companyPosition.message}
                                </p>
                              )}
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="owner"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Owner:
                              </label>
                              <div
                                className={`form-control-contact-radio${
                                  errors.companyOwner
                                    ? "border border-danger"
                                    : ""
                                }`}
                              >
                                <div className="form-check form-check-inline ">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="ownerYes"
                                    value={true}
                                    {...register("companyOwner", {})}
                                  />

                                  <label
                                    className="form-check-label"
                                    htmlFor="ownerYes"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check form-check-inline mx-4">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="ownerNo"
                                    value={false}
                                    {...register("companyOwner")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="ownerNo"
                                  >
                                    No
                                  </label>
                                </div>
                                {errors.companyOwner && (
                                  <p className="text-danger">
                                    {errors.companyOwner.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="companyPercentageOwn"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Percentage Own:
                              </label>

                              <input
                                type="text"
                                className={`form-control-contact black-border${
                                  errors.companyPercentageOwn
                                    ? " border border-danger"
                                    : ""
                                }`}
                                maxLength={4}
                                id="companyPercentageOwn"
                                {...register("companyPercentageOwn", {
                                  validate: validatePercentOwn,
                                  valueAsNumber: true,
                                })}
                                max="100"
                                min="1"
                                step="1" // Ensures only whole numbers are accepted
                                onInput={(e) => {
                                  let value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  if (value.length > 3) {
                                    value = value.slice(0, 3);
                                  }
                                  if (Number(value) > 100) {
                                    value = "100";
                                  }
                                  e.target.value = value;
                                }}
                                style={{ width: "60px" }}
                              />
                              <div className="input-group-text">%</div>
                              {errors.companyPercentageOwn && (
                                <p className="text-danger">
                                  {errors.companyPercentageOwn.message}
                                </p>
                              )}
                            </div>

                            <div className="form-group d-flex align-items-start mb-3">
                              <label
                                htmlFor="cellNo"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Mobile:
                              </label>

                              <div className="d-flex flex-column flex-grow-1">
                                {workFieldsPhone.map((item, index) => (
                                  <div
                                    key={item.id}
                                    className="d-flex align-items-center mb-1"
                                  >
                                    <Controller
                                      name={`workPhone.${index}.value`}
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <PhoneInput
                                            preferredCountries={"us"}
                                            value={field.value}
                                            // inputProps={{ required: true }}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            disableDialCodeAndPrefix={true}
                                            width={100}
                                            disableFocusAfterCountrySelect={
                                              false
                                            }
                                            hideDropdown={true}
                                            // disableCountryGuess={true}
                                            className="black-border"
                                            style={{ width: "150px" }}
                                          />
                                          {index === 0 ? (
                                            <button
                                              type="button"
                                              className="btn btn-outline-primary btn-circle ml-2"
                                              onClick={() =>
                                                appendWorkPhone({
                                                  value: "",
                                                  type: "Personal",
                                                })
                                              }
                                            >
                                              <i className="fa fa-plus"></i>
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              className="btn btn-outline-primary btn-circle ml-2"
                                              onClick={() =>
                                                removeWorkPhone(index)
                                              }
                                              style={{ color: "red" }}
                                            >
                                              <i className="fa fa-minus"></i>
                                            </button>
                                          )}
                                        </>
                                      )}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="form-group d-flex align-items-start mb-3">
                              <label
                                htmlFor="workEmail"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Email:
                              </label>

                              <div className="d-flex flex-column flex-grow-1">
                                {workFieldsEmail.map((item, index) => (
                                  <>
                                    <div className="d-flex">
                                      <input
                                        type="email"
                                        className={`form-control-contact black-border mb-1${
                                          errors.email
                                            ? "border border-danger"
                                            : ""
                                        }`}
                                        id="email"
                                        maxLength={256}
                                        {...register(`workEmail.${index}.value`, {
                                          validate: validateEmail,
                                        })}
                                      />

                                      {index === 0 ? (
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary btn-circle ml-2"
                                          onClick={() =>
                                            appendWorkEmail({
                                              value: "",
                                              type: "Personal",
                                            })
                                          }
                                        >
                                          <i className="fa fa-plus"></i>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary btn-circle ml-2"
                                          onClick={() => removeWorkEmail(index)}
                                          style={{ color: "red" }}
                                        >
                                          <i className="fa fa-minus"></i>
                                        </button>
                                      )}
                                    </div>
                                    {errors?.email && (
                                      <p className="text-danger">
                                        {errors?.email[index]?.value.message}
                                      </p>
                                    )}
                                  </>
                                ))}
                              </div>
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="url"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                URL:
                              </label>
                              <input
                                type="text"
                                className={`form-control-contact black-border ${
                                  errors.companyUrl
                                    ? "border border-danger"
                                    : ""
                                }`}
                                id="url"
                                maxLength={256}
                                //placeholder="www.abccompany.com"
                                {...register("companyUrl", {
                                  validate: validateURL,
                                })}
                              />
                              {errors.companyUrl && (
                                <p className="text-danger">
                                  {errors.companyUrl?.message}
                                </p>
                              )}
                            </div>
                          </div>

                          {/* </div> */}
                          <div className="col-md-4">
                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="companyStreetAddress"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Street Address:
                              </label>
                              <input
                                type="text"
                                className={`form-control-contact black-border ${
                                  errors.companyStreetAddress
                                    ? "border border-danger"
                                    : ""
                                }`}
                                id="streetAddress"
                                maxLength={256}
                                //placeholder="Enter street address"
                                {...register("companyStreetAddress", {})}
                              />
                              {errors.companyStreetAddress && (
                                <p className="text-danger">
                                  {errors.companyStreetAddress.message}
                                </p>
                              )}
                            </div>
                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="suiteNo"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Suite #:
                              </label>
                              <input
                                type="text"
                                className={`form-control-contact black-border ${
                                  errors.companySuiteNo
                                    ? "border border-danger"
                                    : ""
                                }`}
                                id="suiteNumber"
                                maxLength={128}
                                //placeholder="STE 200"
                                {...register("companySuiteNo", {
                                  validate: validateSuiteNumber,
                                })}
                              />
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="city"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                City:
                              </label>
                              <input
                                type="text"
                                className={`form-control-contact black-border ${
                                  errors.companyCity
                                    ? "border border-danger"
                                    : ""
                                }`}
                                id="city"
                                maxLength={128}
                                //placeholder="Amsterdam"
                                {...register("companyCity", {
                                  validate: (value) =>
                                    value ? validateName(value, "city") : null,
                                })}
                              />
                              {errors.companyCity && (
                                <p className="text-danger">
                                  {errors.companyCity.message}
                                </p>
                              )}
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="state"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                State:
                              </label>
                              <input
                                type="text"
                                className={`form-control-contact black-border ${
                                  errors.companyState
                                    ? "border border-danger"
                                    : ""
                                }`}
                                //placeholder="Enter State"
                                id="state"
                                maxLength={256}
                                {...register("companyState", {
                                  validate: (value) =>
                                    value ? validateName(value, "state") : null,
                                })}
                              />
                              {errors.companyState && (
                                <p className="text-danger">
                                  {errors.companyState.message}
                                </p>
                              )}
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="zip"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Zip:
                              </label>
                              <input
                                type="text"
                                className={`form-control-contact black-border ${
                                  errors.companyZip
                                    ? "border border-danger"
                                    : ""
                                }`}
                                id="zip"
                                maxLength={5}
                                //placeholder="Enter zip code"
                                {...register("companyZip", {
                                  validate: validateZipCode,
                                })}
                              />
                              {errors.companyZip && (
                                <p className="text-danger">
                                  {errors.companyZip.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="dept"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Dept:
                              </label>
                              <input
                                type="text"
                                className={`form-control-contact black-border ${
                                  errors.companyDept
                                    ? "border border-danger"
                                    : ""
                                }`}
                                id="dept"
                                maxLength={128}
                                //placeholder="IT Deptartment"
                                {...register("companyDept", {})}
                              />
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="mailStop"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Mail Stop:
                              </label>
                              <input
                                type="text"
                                className={`form-control-contact black-border ${
                                  errors.companyMailStop
                                    ? "border border-danger"
                                    : ""
                                }`}
                                id="mailStop"
                                maxLength={256}
                                //placeholder="BLDG-45-ROOM-101"
                                {...register("companyMailStop", {
                                  pattern: {
                                    value: /^[a-zA-Z0-9\- ]{1,20}$/,
                                    message:
                                      "Mailstop must be between 1 and 20 characters and can include letters, numbers, hyphens, and spaces.",
                                  },
                                })}
                              />
                              {errors.companyMailStop && (
                                <p className="text-danger">
                                  {errors.companyMailStop?.message}
                                </p>
                              )}
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="canCallAtWork"
                                className="mr-2"
                                style={{ width: "200px" }}
                              >
                                Can Call At Work:
                              </label>
                              <div
                                className={`form-control-contact-radio${
                                  errors.companyCanCallAtWork
                                    ? "border border-danger"
                                    : ""
                                }`}
                              >
                                <div className="form-check form-check-inline ">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="canCallAtWork"
                                    value={true}
                                    {...register("companyCanCallAtWork", {})}
                                  />

                                  <label
                                    className="form-check-label "
                                    htmlFor="canCallAtWork"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check form-check-inline mx-4">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="ownerNo"
                                    value={false}
                                    {...register("companyCanCallAtWork")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="ownerNo"
                                  >
                                    No
                                  </label>
                                </div>
                                {errors.companyCanCallAtWork && (
                                  <p className="text-danger">
                                    {errors.companyCanCallAtWork.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body custom-field-button">
                <div className="row">
                  <div className="col-12 align-self-center">
                    <div className="row">
                      <div className="col-lg-12">
                        <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                          Other Info
                        </h4>

                        <div className="row">
                          {/* First Column */}
                          <div className="col-md-4">
                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="socialSecurity"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Social Security #:
                              </label>
                              <input
                                id="socialSecurity"
                                type="text"
                                maxLength={256}
                                //placeholder="123-45-6789"
                                className={`form-control-contact black-border ${
                                  errors.socialSecurity
                                    ? "border border-danger"
                                    : ""
                                }`}
                                {...register("socialSecurity", {
                                  validate: validateSocialSecurity,
                                })}
                              />
                            </div>
                            {errors.socialSecurity && (
                              <p className="text-danger">
                                {errors.socialSecurity.message}
                              </p>
                            )}

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="citizenshipStatus"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Citizenship Status:
                              </label>
                              <select
                                id="citizenshipStatus"
                                className={`form-control-contact black-border ${
                                  errors.citizenshipStatus
                                    ? "border border-danger"
                                    : ""
                                }`}
                                {...register("citizenshipStatus", {})}
                              >
                                <option value="">Select</option>
                                <option value="US Citizen">US Citizen</option>
                                <option value="LPR/Green Card (Legal Permanent Resident)">
                                  LPR/Green Card (Legal Permanent Resident)
                                </option>
                                <option value="F-1 Visa (Student or Work Visa)">
                                  F-1 Visa (Student or Work Visa)
                                </option>
                                <option value="B-1 or B-2 Visa">
                                  B-1 or B-2 Visa
                                </option>
                                <option value="Undocumented Immigrant">
                                  Undocumented Immigrant
                                </option>
                              </select>
                            </div>
                            {errors.citizenshipStatus && (
                              <div className="text-danger">
                                {errors.citizenshipStatus.message}
                              </div>
                            )}
                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="driversLicense"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Drivers License #:
                              </label>

                              <input
                                id="driversLicense"
                                type="text"
                                //placeholder="AB1234432C43"
                                maxLength={128}
                                className={`form-control-contact black-border ${
                                  errors.driversLicense
                                    ? "border border-danger"
                                    : ""
                                }`}
                                {...register("driversLicense", {
                                  validate: validateNumber,
                                })}
                              />
                              {errors.driversLicense && (
                                <p className="text-danger">
                                  {errors.driversLicense.message}
                                </p>
                              )}
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="driversLicenseState"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Drivers License State:
                              </label>

                              <input
                                id="driversLicenseState"
                                type="text"
                                maxLength={256}
                                //placeholder="US"
                                className={`form-control-contact black-border ${
                                  errors.driversLicenseState
                                    ? "border border-danger"
                                    : ""
                                }`}
                                {...register("driversLicenseState", {})}
                              />
                              {errors.driversLicenseState && (
                                <p className="text-danger">
                                  {errors.driversLicenseState.message}
                                </p>
                              )}
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="gender"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Gender:
                              </label>
                              <select
                                id="gender"
                                className={`form-control-contact black-border ${
                                  errors.gender ? "border border-danger" : ""
                                }`}
                                {...register("gender", {})}
                              >
                                <option value="">Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Prefer not to respond">
                                  Prefer not to respond
                                </option>
                              </select>
                              {errors.gender && (
                                <div className="text-danger">
                                  {errors.gender.message}
                                </div>
                              )}
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="dob"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                DOB:
                              </label>
                              <div>
                                <Controller
                                  control={control}
                                  name="dob"
                                  defaultValue={null}
                                  render={({ field }) => {
                                    return (
                                      <DatePicker
                                        value={
                                          field.value
                                            ? new Date(field.value)
                                            : null
                                        }
                                        onChange={(date) => {
                                          const selectedDate = date
                                            ? date.toDate()
                                            : null;
                                          field.onChange(selectedDate);
                                          handleDateChange(selectedDate, "dob");
                                        }}
                                        format="DD/MM/YYYY"
                                        className="form-control-contact custom-date-picker"
                                        calendarPosition="bottom-center"
                                        selected={
                                          field.value
                                            ? new Date(field.value)
                                            : null
                                        }
                                        maxDate={new Date()}
                                        style={{
                                          height: "calc(1.5em + 0.75rem + 2px)",
                                          width: "150px",
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </div>
                              {errors.dob && (
                                <p className="text-danger">
                                  {errors.dob.message}
                                </p>
                              )}
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="age"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Age:
                              </label>
                              <input
                                id="age"
                                type="text"
                                maxLength={2}
                                className="form-control-contact black-border"
                                {...register("age", {})}
                                readOnly
                                style={{ width: "50px" }}
                              />
                            </div>
                            {errors.age && (
                              <div className="invalid-feedback">
                                {errors.age.message}
                              </div>
                            )}
                          </div>

                          <div className="col-md-4">
                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="medicare"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Medicare:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  maxLength={256}
                                  className={`form-control-contact black-border ${
                                    errors.medicareNumber
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="medicareNumber"
                                  //placeholder="123-45-6789"
                                  {...register("medicareNumber", {
                                    validate: validateMedicareNumber,
                                  })}
                                />
                                {errors.medicareNumber && (
                                  <p className="text-danger">
                                    {errors.medicareNumber.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="medicarePlanType"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Medicare Plan Type:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  maxLength={256}
                                  className={`form-control-contact black-border ${
                                    errors.medicarePlanType
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="medicarePlanType"
                                  //placeholder="Gold"
                                  {...register("medicarePlanType", {
                                    validate: (value) =>
                                      value
                                        ? validateName(value, "Plan Type")
                                        : null,
                                  })}
                                />
                                {errors.medicarePlanType && (
                                  <p className="text-danger">
                                    {errors.medicarePlanType.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="medicareCompany"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Medicare Company:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  maxLength={256}
                                  className={`form-control-contact black-border ${
                                    errors.medicareCompany
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="medicareCompany"
                                  //placeholder="LIC"
                                  {...register("medicareCompany", {
                                    validate: (value) =>
                                      value
                                        ? validateName(value, "Company Name")
                                        : null,
                                  })}
                                />
                                {errors.medicareCompany && (
                                  <p className="text-danger">
                                    {errors.medicareCompany.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            {/* <div className="form-group">
                              <p> Medicare Enrollment Dates</p>

                              <div className="form-group d-flex align-items-center mb-3">
                                <label
                                  htmlFor="partAMedicareEnrollmentDate"
                                  className="mr-2"
                                  style={{ width: "150px" }}
                                >
                                  Part A:
                                </label>

                                <Controller
                                  control={control}
                                  name="partAMedicareEnrollmentDate"
                                  defaultValue={null}
                                  render={({ field }) => {
                                    return (
                                      <DatePicker
                                        value={
                                          field.value
                                            ? new Date(field.value)
                                            : null
                                        }
                                        onChange={(date) => {
                                          const selectedDate = date
                                            ? date.toDate()
                                            : null;
                                          field.onChange(selectedDate);
                                          handleDateChange(
                                            selectedDate,
                                            "partA"
                                          );
                                        }}
                                        format="DD/MM/YYYY"
                                        className="form-control-contact custom-date-picker"
                                        calendarPosition="bottom-center"
                                        selected={
                                          field.value
                                            ? new Date(field.value)
                                            : null
                                        }
                                        maxDate={new Date()}
                                      />
                                    );
                                  }}
                                />

                                {errors.otherInfo
                                  ?.partAMedicareEnrollmentDate && (
                                  <p className="text-danger">
                                    {
                                      errors.otherInfo
                                        ?.partAMedicareEnrollmentDate.message
                                    }
                                  </p>
                                )}
                              </div>

                              <div className="form-group d-flex align-items-center mb-3">
                                <label
                                  htmlFor="partBMedicareEnrollmentDate"
                                  className="mr-2"
                                  style={{ width: "150px" }}
                                >
                                  Part B:
                                </label>

                                <Controller
                                  control={control}
                                  name="partBMedicareEnrollmentDate"
                                  defaultValue={null}
                                  render={({ field }) => {
                                    return (
                                      <DatePicker
                                        value={
                                          field.value
                                            ? new Date(field.value)
                                            : null
                                        }
                                        onChange={(date) => {
                                          const selectedDate = date
                                            ? date.toDate()
                                            : null;
                                          field.onChange(selectedDate);
                                          handleDateChange(
                                            selectedDate,
                                            "partB"
                                          );
                                        }}
                                        format="DD/MM/YYYY"
                                        className="form-control-contact custom-date-picker"
                                        calendarPosition="bottom-center"
                                        selected={
                                          field.value
                                            ? new Date(field.value)
                                            : null
                                        }
                                        maxDate={new Date()}
                                      />
                                    );
                                  }}
                                />

                                {errors.otherInfo
                                  ?.partBMedicareEnrollmentDate && (
                                  <p className="text-danger">
                                    {
                                      errors.otherInfo
                                        ?.partBMedicareEnrollmentDate.message
                                    }
                                  </p>
                                )}
                              </div>

                              <div className="form-group d-flex align-items-center mb-3">
                                <label
                                  htmlFor="partCMedicareEnrollmentDate"
                                  className="mr-2"
                                  style={{ width: "150px" }}
                                >
                                  Part C:
                                </label>

                                <Controller
                                  control={control}
                                  name="partCMedicareEnrollmentDate"
                                  defaultValue={null}
                                  render={({ field }) => {
                                    return (
                                      <DatePicker
                                        value={
                                          field.value
                                            ? new Date(field.value)
                                            : null
                                        }
                                        onChange={(date) => {
                                          const selectedDate = date
                                            ? date.toDate()
                                            : null;
                                          field.onChange(selectedDate);
                                          handleDateChange(
                                            selectedDate,
                                            "partC"
                                          );
                                        }}
                                        format="DD/MM/YYYY"
                                        className="form-control-contact custom-date-picker"
                                        calendarPosition="bottom-center"
                                        selected={
                                          field.value
                                            ? new Date(field.value)
                                            : null
                                        }
                                        maxDate={new Date()}
                                      />
                                    );
                                  }}
                                />

                                {errors.otherInfo
                                  ?.partCMedicareEnrollmentDate && (
                                  <p className="text-danger">
                                    {
                                      errors.otherInfo
                                        ?.partCMedicareEnrollmentDate.message
                                    }
                                  </p>
                                )}
                              </div>
                            </div> */}
                          </div>

                          <div className="col-md-4">
                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="petsName"
                                // className="col-sm-4 col-form-label"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Pets Name:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  maxLength={256}
                                  type="text"
                                  className="form-control-contact black-border"
                                  id="petsName"
                                  {...register("petsName")}
                                />
                              </div>
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="typeOfPets"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Type Of Pets:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  maxLength={256}
                                  className={`form-control-contact black-border ${
                                    errors.typeOfPets
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  id="typeOfPets"
                                  {...register("typeOfPets")}
                                />
                              </div>
                            </div>

                            <div className="form-group d-flex align-items-center mb-3">
                              <label
                                htmlFor="hobbies"
                                className="mr-2"
                                style={{ width: "150px" }}
                              >
                                Hobbies:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  maxLength={256}
                                  className={`form-control-contact black-border ${
                                    errors.hobbies ? "border border-danger" : ""
                                  }`}
                                  id="hobbies"
                                  {...register("hobbies")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}

            {/* {data?.customForm?.name ? (
                <div className="card">
                  <div className="card-body custom-field-button">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          {data?.customForm.skeleton.map((item, index) =>
                            item.type === "Heading" ? (
                              <div key={item.index} className="col-12">
                                <h3
                                  className="page-title text-truncate text-dark font-weight-medium mb-4"
                                  id={item?.name}
                                >
                                  {item?.heading}
                                </h3>
                              </div>
                            ) : item.type === "SubHeading" ? (
                              <div key={item.index} className="col-12">
                                <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                                  {`${item?.heading}`}
                                </h4>
                              </div>
                            ) : item.type === "Text" ? (
                              <div key={item.index} className="col-4">
                                <div className="form-group d-flex align-items-center mb-3">
                                  <label htmlFor={`${item.name}`}>
                                    {item.label}
                                  </label>
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className="form-control-contact black-border"
                                    id={`${item.name}`}
                                    {...register(`customData.${item.name}`, {
                                      required: item?.required,
                                      validate: (value) =>
                                        validateName(value, `${item.label}`),
                                    })}
                                  />
                                  {errors[item.name] && (
                                    <p className="text-danger">
                                      {errors[item.name].message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            ) : item.type === "Number" ? (
                              <div key={item.index} className="col-4">
                                <div className="form-group d-flex align-items-center mb-3">
                                  <label htmlFor={`${item.name}`}>
                                    {item.label}
                                  </label>
                                  <input
                                    type="number"
                                    maxLength={256}
                                    className="form-control-contact black-border"
                                    id={`${item.name}`}
                                    {...register(`customData.${item.name}`, {
                                      required: item?.required,
                                    })}
                                  />
                                  {errors[item.name] && (
                                    <p className="text-danger">
                                      {errors[item.name].message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            ) : item.type === "Date" ? (
                              <div key={item.index} className="col-4">
                                <div className="form-group d-flex align-items-center mb-3">
                                  <label htmlFor={`${item.name}`}>
                                    {item.label}
                                  </label>
                                  <input
                                    type="date"
                                    maxLength={256}
                                    className="form-control-contact black-border"
                                    id={`${item.name}`}
                                    {...register(`customData.${item.name}`, {
                                      required: item?.required,
                                    })}
                                  />
                                  {errors[item.name] && (
                                    <p className="text-danger">
                                      {errors[item.name].message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            ) : item.type === "TextArea" ? (
                              <div key={item.index} className="col-4">
                                <div className="form-group d-flex align-items-center mb-3">
                                  <label htmlFor={`${item.name}`}>
                                    {item.label}
                                  </label>
                                  <textarea
                                    type="text"
                                    maxLength={256}
                                    className="form-control-contact black-border"
                                    id={`${item.name}`}
                                    {...register(`customData.${item.name}`, {
                                      required: item?.required,
                                      validate: (value) =>
                                        validateName(value, `${item.label}`),
                                    })}
                                  />
                                  {errors[item.name] && (
                                    <p className="text-danger">
                                      {errors[item.name].message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            ) : item.type === "Select" ? (
                              <div key={item.index} className="col-4">
                                <div className="form-group d-flex align-items-center mb-3">
                                  <label htmlFor={`${item.name}`}>
                                    {item.label}
                                  </label>
                                  <select
                                    className="form-control-contact black-border"
                                    id={`${item.name}`}
                                    {...register(`customData.${item.name}`, {
                                      required: item?.required,
                                    })}
                                  >
                                    {item.option.map((optionItem) => (
                                      <option value={optionItem.value}>
                                        {optionItem.label}
                                      </option>
                                    ))}
                                  </select>
                                  {errors[item.name] && (
                                    <p className="text-danger">
                                      {errors[item.name].message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            ) : item.type === "Radio" ? (
                              <div key={item.index} className="col-4">
                                <div className="form-group d-flex align-items-center mb-3">
                                  <label htmlFor={`${item.name}`}>
                                    {item.label}
                                  </label>
                                  <div id={`${item.name}`}>
                                    {item.option.map((optionItem) => (
                                      <div
                                        key={optionItem.value}
                                        className="form-check"
                                      >
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          id={`${item.name}-${optionItem.value}`}
                                          value={optionItem.value}
                                          {...register(
                                            `customData.${item.name}`,
                                            {
                                              required: item?.required,
                                            }
                                          )}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`${item.name}-${optionItem.value}`}
                                        >
                                          {optionItem.label}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                  {errors[item.name] && (
                                    <p className="text-danger">
                                      {errors[item.name].message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )} */}

            <div className="row">
              <div className="col-12 d-flex justify-content-center align-items-center">
                {/* Start Page Content */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <div className="form-group row">
                          <button
                            type="submit"
                            className="btn_green btn_black_text mx-2"
                          >
                            Save
                          </button>
                          {/* <button
                              type="button"
                              // className="next-step btn_green mx-2"
                              className={`next-step mx-2 ${
                                isSaved && currentForm < 3
                                  ? "btn_green"
                                  : "btn_disabledNext"
                              }`}
                              onClick={handleNext}
                              disabled={!isSaved || currentForm === 3}
                            >
                              Next
                            </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddPersonalContactForm;
