import React, { useEffect, useState } from "react";
import ViewForm from "../customForm/viewForm/ViewForm";
import { useParams } from "react-router-dom";
import appConstant from "../../json/appConstant.json";
import useApis from "../../apis/useApis";

const Quote = () => {
  const { id, type } = useParams();
  console.log(id, type);
  const [data, setData] = useState([]);
  const [selectedSkeleton, setSelectedSkeleton] = useState(null);
  const { jsonApi } = useApis();

  useEffect(() => {
    init();
  }, [id, type]);

  const init = async () => {
    const res = await jsonApi(
      appConstant.getCustomFromSkeletonByName,
      { type: type.toString() },
      null
    );
    const res2 = await jsonApi(appConstant.getSingleUserDetail, { id }, null);

    if (!res.error && !res2.error) {
      setData(res2.data.data);
      setSelectedSkeleton(res.data.data);
    }
  };

  return (
    <div className="container-fluid">
      <ViewForm
        data={data}
        fromSkeleton={selectedSkeleton ? selectedSkeleton.skeleton : []}
        closeModal={() => console.log("first")}
        formListName={data?.first_name}
        show={true}
        demo={false}
        customForm={selectedSkeleton}
      />
    </div>
  );
};

export default Quote;
