import React, { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContactHistory from "../ContactHistory";
import { CallCenterContext } from "../../../context/CallCenterContext";
import moment from "moment";
import ImageComponent from "../../../media/ImageComponent";
import useFetchUserDetail from "../../../hooks/useFetchUserDetail";

const PersonalContactProfileData = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { makeCall } = useContext(CallCenterContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [search, setSearch] = useState("");
  const { data } = useFetchUserDetail(id);
  console.log(data);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <>
              {error ? (
                <div className="alert alert-danger">{error}</div>
              ) : (
                <>
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 align-self-center pt-4">
                          <h2 className="page-title text-truncate text-dark font-weight-medium">
                            Contact Profile Data
                          </h2>
                          <div className="row">
                            <div className="col-sm d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                <h2 className="page-title text-truncate text-dark font-weight-medium mb-0">
                                  {data?.fullName
                                    ? (() => {
                                        const nameParts =
                                          data?.fullName.split(" ");
                                        const firstName = nameParts[0];
                                        const lastName =
                                          nameParts.length > 1
                                            ? nameParts[nameParts.length - 1]
                                            : null;

                                        // If a last name exists (meaning first and last name are provided), show last name, first name
                                        if (lastName) {
                                          return `${lastName}, ${firstName}`;
                                        }
                                        // If only first name exists, return just first name
                                        return firstName;
                                      })()
                                    : ""}
                                </h2>
                                <button
                                  type="button"
                                  className="btn waves-effect waves-light btn-rounded btn-sm btn-primary ml-3"
                                  data-toggle="modal"
                                  data-target="#editprofilepicModal"
                                  onClick={() =>
                                    navigate(
                                      `/contact/personal/edit/detail?id=${id}`
                                    )
                                  }
                                >
                                  Edit
                                </button>
                              </div>
                            </div>

                            <div className="col-sm text-right d-inline-block">
                              <ImageComponent
                                className="rounded-circle profile-image"
                                alt={data?.fullName}
                                src={
                                  data?.profileImage ||
                                  "/assets/images/dummy.png"
                                }
                              />
                            </div>
                          </div>

                          <p className="page-title text-truncate text-dark font-weight-medium">
                            {data?.fullName || ""}
                          </p>

                          <div className="row mt-2">
                            <div className="col-lg-4 ">
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label d-flex align-items-center"
                                >
                                  Position:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center d-flex align-items-center">
                                  {data?.position || ""}
                                </div>
                              </div>

                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Company Name:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center d-flex align-items-center">
                                  {data?.companyName || " "}
                                </div>
                              </div>

                              <div className="form-group row mb-0">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Cell No:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark">
                                  {data?.phone && data?.phone?.length > 0 ? (
                                    <div className="d-flex flex-column">
                                      {data?.phone
                                        .filter(
                                          (phone) =>
                                            !phone.value.startsWith("+1")
                                        )
                                        .map((phone, index) => (
                                          <div
                                            key="{phone._id}"
                                            onClick={() => {
                                              makeCall(phone.value, data._id);
                                              navigate("/phone/call");
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {phone.value}
                                          </div>
                                        ))}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Corporate #:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark">
                                  {data?.workPhone &&
                                  data?.workPhone?.length > 0 ? (
                                    <div className="d-flex flex-column">
                                      {data?.workPhone
                                        .filter(
                                          (workPhone) =>
                                            !workPhone.value.startsWith("+1")
                                        )
                                        .map((workPhone, index) => (
                                          <div
                                            key="{workPhone._id}"
                                            onClick={() => {
                                              makeCall(workPhone.value);
                                              navigate("/phone/call");
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {workPhone.value}
                                          </div>
                                        ))}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="form-group row mb-0">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Email:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.email && data?.email?.length > 0 ? (
                                    <div className="d-flex flex-column">
                                      {data?.email?.map((email, index) => (
                                        <div
                                          key="{email._id}"
                                          onClick={() =>
                                            (window.location.href = `mailto:${email.value}`)
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          {email.value}
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="form-group row mb-0">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                ></label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.workEmail &&
                                  data?.workEmail?.length > 0 ? (
                                    <div className="d-flex flex-column">
                                      {data?.workEmail?.map(
                                        (workEmail, index) => (
                                          <div
                                            key="{workEmail._id}"
                                            onClick={() =>
                                              (window.location.href = `mailto:${workEmail.value}`)
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            {workEmail.value}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="form-group mb-0 row mt-2">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Personal Address:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  <div>
                                    {[
                                      data?.primaryStreetAddress,
                                      data?.primaryCity,
                                      data?.primaryState,
                                      data?.primaryZip,
                                      data?.primarySuiteNo,
                                    ]
                                      .filter(Boolean)
                                      .join(", ")}
                                  </div>
                                </div>
                              </div>

                              <div className="form-group mb-0 row mt-2">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Office Address:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {[
                                    data?.companyStreetAddress,
                                    data?.companyCity,
                                    data?.companyState,
                                    data?.companyZip,
                                    data?.companySuiteNo,
                                  ]
                                    .filter(Boolean)
                                    .join(", ")}
                                </div>
                              </div>

                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Anniversary:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.weddingAnniv || " "}
                                </div>
                              </div>

                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  DOB:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.dob
                                    ? moment(data?.dob).format("YYYY/MM/DD")
                                    : " "}
                                </div>
                              </div>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Age:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.age || " "}
                                </div>
                              </div>

                              {/* <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Relationship Type
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.relationshipType || " "}
                                </div>
                              </div> */}
                              {/* <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Gender
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.gender || " "}
                                </div>
                              </div> */}

                              {/* <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Year of Marriage
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.yearOfMarriage ||
                                    " "}
                                </div>
                              </div> */}

                              {/* <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Citizenship Status
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.citizenshipStatus ||
                                    " "}
                                </div>
                              </div>
                           
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Direct #
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.directNumber ||
                                    " "}
                                </div>
                              </div>
                           
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Extension #
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.extensionNumber ||
                                    " "}
                                </div>
                              </div>
                            
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Dept{" "}
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                {data?.dept || " "}
                              </div>
                              </div> 
                                  <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Type of Insurance
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.typeOfInsurance ||
                                    " "}
                                </div>
                              </div>
                               */}
                            </div>

                            <div className="col-lg-4">
                              {/* <h4 className="page-title text-truncate text-dark font-weight-medium my-4">
                                Business Information
                              </h4> */}

                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Social Security #:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.socialSecurity || " "}
                                </div>
                              </div>

                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Drivers License #:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.driversLicense || " "}
                                </div>
                              </div>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Drivers License State:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.driversLicenseState || " "}
                                </div>
                              </div>

                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Medicare #:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.medicareNumber || " "}
                                </div>
                              </div>
                              <h5 className="pt-2">
                                Medicare Enrollment Dates:
                              </h5>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Part A:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.partA || " "}
                                </div>
                              </div>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Part B:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.partB || " "}
                                </div>
                              </div>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Part C:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.partC || " "}
                                </div>
                              </div>

                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Medicare Plan Type:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.medicarePlanType || " "}
                                </div>
                              </div>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Medicare Company:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.medicareCompany || " "}
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <h4 className="page-title text-truncate text-dark font-weight-medium ">
                                Spouse Name:
                                {data?.spouse?.fullName || " "}
                              </h4>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  Age:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {data?.spouse?.age || " "}
                                </div>
                              </div>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-6 col-form-label"
                                >
                                  DOB:
                                </label>
                                <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                  {/* ? new Date(data.spouse.dob).toLocaleDateString('en-CA').replace(/-/g, '/') */}
                                  {data?.spouse?.dob
                                    ? moment(data.spouse.dob).format(
                                        "YYYY/MM/DD"
                                      )
                                    : " "}
                                </div>
                              </div>
                              <h4 className="page-title text-truncate text-dark font-weight-medium my-4">
                                Dependents:
                              </h4>
                              {data?.dependents?.length > 0 ? (
                                data?.dependents.map((dependent, index) => (
                                  <div key={index}>
                                    <h5 className="page-title text-truncate text-dark font-weight-medium my-4">
                                      Child # {index + 1}:{" "}
                                      {dependent?.fullName || " "}
                                    </h5>
                                    <div className="form-group mb-0 row">
                                      <label
                                        htmlFor="staticEmail"
                                        className="col-sm-6 col-form-label"
                                      >
                                        Age:
                                      </label>
                                      <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center font-weight-bold text-dark">
                                        {dependent?.age || " "}
                                      </div>
                                    </div>
                                    <div className="form-group mb-0 row">
                                      <label
                                        htmlFor="staticEmail"
                                        className="col-sm-6 col-form-label"
                                      >
                                        DOB:
                                      </label>
                                      <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
                                        {dependent?.dob
                                          ? moment(dependent?.dob).format(
                                              "YYYY/MM/DD"
                                            )
                                          : " "}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <p>No dependents</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ContactHistory
                    userDetails={data}
                    setPage={setPage}
                    totalData={totalData}
                    limit={limit}
                    setLimit={setLimit}
                    page={page}
                    setSearch={setSearch}
                  />
                </>
              )}
            </>
          </div>

          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto img-fluid"
              title="Ads"
              alt="ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalContactProfileData;

// {data?.customForm?.name ? (
//   <div className="col">
//     {data?.customForm.skeleton.map(
//       (item) => (
//         <>
//           {item.type === "Heading" ? (
//             <h4 className="page-title text-dark font-weight-medium my-4 border-bottom pb-2">
//               {item.heading}
//             </h4>
//           ): item.type === "SubHeading" ? (
//             <h5 className="page-title text-dark font-weight-medium my-4 border-bottom pb-2">
//               {item.heading}
//             </h5>
//           ): (
//             <>
//               {" "}
//               <div className="form-group row mb-3 d-flex align-items-center">
//                 <label
//                   htmlFor="staticEmail"
//                   className="col-sm-6 col-form-label text-secondary"
//                 >
//                   {item.label}
//                 </label>
//                 <div className="col-sm-6 font-weight-bold text-dark d-flex align-items-center">
//                   {Array.isArray(
//                     data?.customData[
//                       item?.name
//                     ]
//                   )
//                     ? data?.customData[
//                         item?.name
//                       ][0].value
//                    : data?.customData[
//                         item?.name
//                       ] || (
//                         <span className="text-muted">
//                           No data
//                         </span>
//                       )}
//                 </div>
//               </div>
//             </>
//           )}
//         </>
//       )
//     )}
//   </div>
// ): (
//   <></>
// )}
