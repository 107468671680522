import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useApis from "../../../apis/useApis";
import appConstant from "../../../json/appConstant.json";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  validateName,
  validateSocialSecurity,
  validateNumber,
} from "../../validation/Validation";
import DragsAndDrop from "../../../image/DragsAndDrop";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-multi-date-picker";

const DependentsForm = () => {
  document.title = "Dependent  - Digital Call Center";
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const navigate = useNavigate();
  const isEditingDependent = window.location.pathname.includes('/edit/dependent');
  const defaultDependent = {
    fullName: "",
    age: "",
    dob: "",
    gender: "",
    ssn: "",
    custodyType: "",
    relationshipType: "",
    driversLicense: "",
    driversLicenseState: "",
    yearsOfDriving: "",
    bloodRelativeInfo: "",
    biologicalParent: "",
    currentLivingProfile: "",
    gradeLevel: "",
    nameOfSchool: "",
    addressOfSchool: "",
    major: "",
  };

  const [dependents, setDependents] = useState([defaultDependent]);
  const { jsonApi, mediaApi } = useApis();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { dependents } });

  // const onSubmit = async (data) => {
  //     const payload = {
  //       userId: searchParams.get("id"),
  //       dependents: data.dependents,
  //     };
  //     console.log(data.dependents);
  //     const response = await jsonApi(
  //       appConstant.addDependent,
  //       null,
  //       payload,
  //       true,
  //       false
  //     );
  //     if (!response.error) {
  //       navigate("/contact/personal");
       
  //     } else {
  //       setError(response.error.message || "An error occurred");
  //     }
  // };

  const onSubmit = async (data) => {
    try {
      const payload = {
        userId: searchParams.get("id"),
        dependents: data.dependents,
      };
      console.log(data.dependents);
      const response = await jsonApi(
        appConstant.addDependent,
        null,
        payload,
        true,
        false
      );
      if (!response.error) {
        navigate("/contact/personal");
        // reset();
        // window.alert("All the details successfully saved.");
      } else {
        setError(response.error.message || "An error occurred");
      }
      if (data.logo) {
        const formData = new FormData();
        formData.append("logo", data.logo);
        const response2 = await mediaApi(
          appConstant.updateDependentProfile,
          null,
          formData,
          null
        );
        if (!response2.error) {
          // await homeApi();
          setError("");
        }
      }
    } catch (err) {
      setError("An unexpected error occurred");
    }
  };

  const handleDateChange = (date, index) => {
    if (date instanceof Date && !isNaN(date)) {
      setValue(`dependents.${index}.dob`, date, { shouldValidate: true });

      const calculateAge = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      };

      const age = calculateAge(date);
      setValue(`dependents.${index}.age`, age);
      console.log(age);
    } else {
      setError("Invalid date format. Please select a valid date.");
    }
  };

  const today = new Date();
  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const cardStyle = { marginBottom: "1.5rem" };
  const formGroupStyle = { marginBottom: "1rem" };
  const formControlStyle = { width: "100%" };

  const init = async () => {
    const res = await jsonApi(
      appConstant.getSingleUserDetail,
      { id: searchParams.get("id") },
      {},
      false
    );
    if (!res.error) {
      setSearchParams({
        id: res.data.data._id,
      });
      setData(res.data.data);
      // populateForm(res.data.data);
    }
  };
  useEffect(() => {
    if (isEditingDependent) {
      init();
    }
    // init();
  }, [searchParams.get("id")]);

  useEffect(() => {
    if (data?.dependents && data.dependents.length > 0 && isEditingDependent) {
      setDependents(data.dependents); // Populate dependents when editing
    }
  }, [data]);

  useEffect(() => {
    if (dependents?.length > 0 && isEditingDependent) {

    reset({ dependents });
    }
  }, [dependents, reset]);

  // Populate the form with existing data when editing
  const populateForm = (data) => {
    if (data?.dependents) {
      data.dependents.forEach((dependent, index) => {
        Object.keys(dependent).forEach((key) => {
          setValue(`dependents[${index}].${key}`, dependent[key]);
        });
      });
    }
  };

  // Populate the form when data changes (editing case)
  // useEffect(() => {
  //   if (data?.dependents) {
  //     populateForm(data);
  //   }
  // }, [data]);
  useEffect(() => {
    if (data?.dependents && isEditingDependent) {
        populateForm(data); // Only populate form if it's not 'add'
    }
}, [data]);

  const onImageChange = async (x, id) => {
    const formData = new FormData();
    formData.append("logo", x);
    const dd = await mediaApi(
      appConstant.updateContactProfile,
      { id },
      formData,
      true,
      true
    );
  };

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {dependents.map((dependent, index) => (
            <>
            <div
              className="col-lg-6 mb-3"
              key={dependent.index}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="card" style={cardStyle}>
                <div className="card-body">
                  <div className="dependent-section">
                    <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 d-flex justify-content-between align-items-center">
                      Dependent Details
                      {/* {index + 1}
                      <div className="button-group d-flex align-items-center">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={addDependent}
                        >
                          Add +
                        </button>
                        {index > 0 && (
                          <button
                            type="button"
                            className="btn btn-danger "
                            onClick={() => removeDependent(index)}
                          >
                            Remove
                          </button>
                        )}
                      </div> */}
                    </h4>
                  </div>

                
                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        {" "}
                        {/* Label takes up 4 columns on medium screens */}
                        <label
                          htmlFor="fullName"
                          className="col-form-label"
                          style={{ marginRight: "10px" }}
                        >
                          Name:
                        </label>
                      </div>
                      <div className="col-12 col-md-6">
                        {" "}
                        {/* Input takes up the rest of the space */}
                        <input
                          type="text"
                          className="form-control-contact black-border"
                          maxLength={256}
                          id="fullName"
                          {...register(`dependents.${index}.fullName`, {
                            validate: (value) => validateName(value, "name"),
                          })}
                          style={formControlStyle}
                        />
                        {errors.dependents?.[index]?.fullName && (
                          <p className="text-danger">
                            {errors.dependents[index].fullName.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">DOB:</label>
                      </div>
                      <div className="col-12 col-md-6">
                        <Controller
                          control={control}
                          name={`dependents.${index}.dob`}
                          defaultValue={null}
                          render={({ field }) => (
                            <DatePicker
                              value={field.value ? new Date(field.value) : null}
                              onChange={(date) => {
                                const selectedDate = date
                                  ? date.toDate()
                                  : null;
                                field.onChange(selectedDate);
                                handleDateChange(selectedDate, index); 
                              }}
                              format="DD/MM/YYYY"
                              className="form-control-contact custom-date-picker"
                              calendarPosition="bottom-center"
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              maxDate={maxDate} 
                              style={{
                                height: "calc(1.5em + 0.75rem + 2px)",
                                width: "236px",
                              }}
                            />
                          )}
                        />
                      </div>

                      {errors.dependents?.[index]?.dob && (
                        <p className="text-danger">
                          {errors.dependents[index].dob.message}
                        </p>
                      )}
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">Age:</label>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center">
                        <input
                          id="age"
                          type="text"
                          maxLength={2}
                          className="form-control-contact black-border"
                          {...register(`dependents.${index}.age`)}
                          readOnly
                          style={{width:"180px"}}
                        />
                        <div className="input-group-text">yrs.</div>

                        {errors.dependents?.[index]?.age && (
                          <p className="text-danger">
                            {errors.dependents[index].age.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">
                          Social Security # :
                        </label>
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          maxLength={256}
                          //placeholder="123-45-6789"
                          className="form-control-contact black-border"
                          {...register(`dependents.${index}.socialSecurity`, {
                            // required: "SSN is required",
                            validate: validateSocialSecurity,
                          })}
                          style={formControlStyle}
                        />
                        {errors.dependents?.[index]?.socialSecurity && (
                          <p className="text-danger">
                            {errors.dependents[index].socialSecurity.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">Custody Type :</label>
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          maxLength={256}
                          //placeholder="Mother/Father"
                          className="form-control-contact black-border"
                          {...register(`dependents.${index}.custodyType`, {
                            // required: "Custody Type is required",
                          })}
                          style={formControlStyle}
                        />
                        {errors.dependents?.[index]?.custodyType && (
                          <p className="text-danger">
                            {errors.dependents[index].custodyType.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">
                          Relationship Type :
                        </label>
                      </div>
                      <div className="col-12 col-md-6">
                        <select
                          className="form-control-contact black-border"
                          {...register(`dependents.${index}.relationshipType`, {
                            // required: "Relationship Type is required",
                          })}
                          style={formControlStyle}
                        >
                          <option value="">Select</option>
                          <option value="Single">Single</option>
                          <option value="Married">Married</option>
                          <option value="Cohabitation (Living Together)">
                            Cohabitation (Living Together)
                          </option>
                          <option value="Separated">Separated</option>
                          <option value="Divorced">Divorced</option>
                        </select>
                        {errors.dependents?.[index]?.relationshipType && (
                          <p className="text-danger">
                            {errors.dependents[index].relationshipType.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">
                          Drivers License # :
                        </label>
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          maxLength={256}
                          //placeholder="KL8782738"
                          className="form-control-contact black-border"
                          {...register(`dependents.${index}.driversLicense`, {
                            // required: "License number is required",
                            validate: validateNumber,
                          })}
                          style={formControlStyle}
                        />
                        {errors.dependents?.[index]?.driversLicense && (
                          <p className="text-danger">
                            {errors.dependents[index].driversLicense.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">
                          Drivers License State :
                        </label>
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          maxLength={256}
                          //placeholder="California"
                          className="form-control-contact black-border"
                          {...register(
                            `dependents.${index}.driversLicenseState`
                          )}
                          style={formControlStyle}
                        />
                        {errors.dependents?.[index]?.driversLicenseState && (
                          <p className="text-danger">
                            {
                              errors.dependents[index].driversLicenseState
                                .message
                            }
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">
                          Years of Driving :
                        </label>
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          maxLength={2}
                          //placeholder="10"
                          className="form-control-contact black-border"
                          {...register(`dependents.${index}.yearsOfDriving`, {
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Years must be a number",
                            },
                          })}
                          style={formControlStyle}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                        />
                        {errors.dependents?.[index]?.yearsOfDriving && (
                          <p className="text-danger">
                            {errors.dependents[index].yearsOfDriving.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">
                          Blood Relative Info :
                        </label>
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          maxLength={256}
                          //placeholder=""
                          className="form-control-contact black-border"
                          {...register(`dependents.${index}.bloodRelativeInfo`)}
                          style={formControlStyle}
                        />
                        {errors.dependents?.[index]?.bloodRelativeInfo && (
                          <p className="text-danger">
                            {errors.dependents[index].bloodRelativeInfo.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">
                          Biological Parent :
                        </label>
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          maxLength={256}
                          //placeholder="Yes/No"
                          className="form-control-contact black-border"
                          {...register(`dependents.${index}.biologicalParent`)}
                          style={formControlStyle}
                        />
                        {errors.dependents?.[index]?.biologicalParent && (
                          <p className="text-danger">
                            {errors.dependents[index].biologicalParent.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">
                          Current Living Profile :
                        </label>
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          maxLength={256}
                          //placeholder="With Parents/ Away"
                          className="form-control-contact black-border"
                          {...register(
                            `dependents.${index}.currentLivingProfile`
                          )}
                          style={formControlStyle}
                        />
                        {errors.dependents?.[index]?.currentLivingProfile && (
                          <p className="text-danger">
                            {
                              errors.dependents[index].currentLivingProfile
                                .message
                            }
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">Grade Level :</label>
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          maxLength={256}
                          //placeholder="10th/12th/Grad"
                          className="form-control-contact black-border"
                          {...register(`dependents.${index}.gradeLevel`)}
                          style={formControlStyle}
                        />
                        {errors.dependents?.[index]?.gradeLevel && (
                          <p className="text-danger">
                            {errors.dependents[index].gradeLevel.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">
                          Name of School :
                        </label>
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          maxLength={256}
                          //placeholder="St. Xavier International"
                          className="form-control-contact black-border"
                          {...register(`dependents.${index}.nameOfSchool`)}
                          style={formControlStyle}
                        />
                        {errors.dependents?.[index]?.nameOfSchool && (
                          <p className="text-danger">
                            {errors.dependents[index].nameOfSchool.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">
                          Address of School :
                        </label>
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          maxLength={256}
                          //placeholder="122, 4th Street, Green Ford"
                          className="form-control-contact black-border"
                          {...register(`dependents.${index}.addressOfSchool`)}
                          style={formControlStyle}
                        />
                        {errors.dependents?.[index]?.addressOfSchool && (
                          <p className="text-danger">
                            {errors.dependents[index].addressOfSchool.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row" style={formGroupStyle}>
                      <div className="col-md-4">
                        <label className="col-form-label">Major :</label>
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          type="text"
                          maxLength={256}
                          //placeholder="Science/Commerce"
                          className="form-control-contact black-border"
                          {...register(`dependents.${index}.major`)}
                          style={formControlStyle}
                        />
                        {errors.dependents?.[index]?.major && (
                          <p className="text-danger">
                            {errors.dependents[index]?.major?.message}
                          </p>
                        )}
                      </div>
                    </div>  
                </div>
              </div>
            </div>
              <div className="col-lg-4 mt-4">
                <div  style={{ width: "200px" }}>
                  
                  <DragsAndDrop
                    imgKey={dependent?.profileImage}
                    className={"admin-profile-round"}
                    heading="Upload Image"
                    inputName="Image"
                    aspect={1 / 1}
                    uploadFile={(x) => {
                      onImageChange(x, dependent._id);
                    }}
                  />
                </div>
              </div>
            </>
          ))}
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <div className="form-group row">
              <button type="submit" className="next-step btn_green mx-2">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DependentsForm;
