import React, { useContext, useEffect, useState } from "react";
import Login from "./pages/login/Login";
import { Context } from "./context/Context";
import { Route, Routes } from "react-router-dom";
import Aside from "./components/aside/Aside";
import CallCentre from "./components/callCentre/CallCentre";
import CoreModule from "./components/coreModule/CoreModule";
import useAuthApis from "./apis/useAuthApis";
import Hierarchy from "./pages/hierarchy/Hierarchy";
import Modules from "./components/modules/Modules";
import Loader from "./components/loader/Loader";
import CallHistory from "./components/callHistory/CallHistory";
import Contact from "./components/contact/Contact";
import AddTask from "./components/task/AddTask";
import ShowTask from "./components/task/ShowTask";
import ShowNotes from "./components/notes/ShowNotes";
import AddNotes from "./components/notes/AddNotes";
import EditTask from "./components/task/EditTask";
import EditNotes from "./components/notes/EditNotes";
import AddPersonalContact from "./components/contact/addPersonalContact/AddPersonalContact";
import SpouseProfileData from "./components/contact/addPersonalContact/SpouseProfileData";
import ForgetPassword from "./components/forgetPassword/ForgetPassword";
import ProfilePasswordUpdate from "./pages/profilePasswordUpdate/ProfilePasswordUpdate";
import UpdateProfile from "./components/updateProfile/UpdateProfile";
import PersonalContactFavourites from "./components/contact/addPersonalContact/PersonalContactFavourites";
import CreateEvents from "./components/events/CreateEvents";
import EventCalendar from "./components/events/EventCalendar";
import ListEvents from "./components/events/ListEvents";
import EditEvents from "./components/events/EditEvents";
import AddEmployee from "./pages/hierarchy/HierarchyFormComponents/AddEmployee";
import APIKey from "./components/apiKey/APIKey";
import UpdateCompanyProfile from "./components/updateCompanyProfile/UpdateCompanyProfile";
import UpdateAdminProfile from "./components/updateAdminProfile/UpdateAdminProfile";
import AddBusinessContact from "./components/contact/addBusinessContact/AddBusinessContact";
import CallCenterContextProvider from "./context/CallCenterContext";
import ClientLead from "./components/LeadData/ClientLead";
import ProspectsLead from "./components/LeadData/ProspectsLead";
import SubscriptionAdmin from "./components/subscription/SubscriptionAdmin";
import SubscriptionCompany from "./components/subscription/SubscriptionCompany";
import SubscriptionDetail from "./components/subscription/SubscriptionDetail";
import PersonalContactProfileData from "./components/contact/addPersonalContact/PersonalContactProfileData";
import BusinessContactData from "./components/contact/addBusinessContact/BusinessContactData";
import BusinessContactFavourites from "./components/contact/addBusinessContact/humanResources/BusinessContactFavourites";
import BusinessContactInformation from "./components/contact/addBusinessContact/BusinessContactInformation";
import HumanResourcesData from "./components/contact/addBusinessContact/humanResources/HumanResourcesData";
import BusinessOwner from "./components/contact/addBusinessContact/humanResources/BusinessOwner";
import BusinessManager from "./components/contact/addBusinessContact/humanResources/BusinessManager";
import BusinessEmployee from "./components/contact/addBusinessContact/humanResources/BusinessEmployee";
import BusinessExecutive from "./components/contact/addBusinessContact/humanResources/BusinessExecutive";
import BusinessContactDetails from "./components/contact/addBusinessContact/BusinessContactDetails";
import UpdateLeadForm from "./components/LeadData/UpdateLeadForm";
import UpdateLeadLifeForm from "./components/LeadData/life/UpdateLeadLifeForm";
import CustomFrom from "./components/customForm/CustomFrom";
import { VICIDialContextProvider } from "./context/VICIDialContext";
import AddLeadsCall from "./components/LeadData/addLeads/AddLeadsCall";
import ToastNotification from "./components/toastNotification/ToastNotification";
import PhoneCall from "./components/callCentre/PhoneCall";
import CampaignsLead from "./components/LeadData/Campaigns";
import AllContactData from "./components/contact/addPersonalContact/AllContactData";
import CampaignList from "./components/LeadData/CampaignList";
import MainHeader from "./components/contact/MainHeader";
import AddPersonalContactForm from "./components/contact/addPersonalContact/AddPersonalContactForm";
import SpouseDataFillUp from "./components/contact/addPersonalContact/SpouseDataFillUp";
import DependentsForm from "./components/contact/addPersonalContact/DependentsForm";
import DependentsProfile from "./components/contact/addPersonalContact/DependentsProfile";
import { useId } from "./context/IdContext";
import Dashboard from "./components/dashboard/Dashboard";
import Quote from "./components/quote/Quote";

const Routing = () => {
  const { isAuthorized, isLoading, setIsLoading, userData } =
    useContext(Context);
  const [firstLoading, setFirstLoading] = useState(true);
  const { homeApi } = useAuthApis();

  const { id } = useId();

  useEffect(() => {
    if (id) {
      // console.log('Fetching data for ID:', id);
    }
  }, [id]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (localStorage.getItem("bearerToken")) {
        await homeApi();
      }
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = async () => {
    setIsLoading(true);
    await homeApi();
    setFirstLoading(false);
    setIsLoading(false);
  };

  return (
    <>
      <Loader open={isLoading} />
      <ToastNotification />
      {firstLoading ? (
        <></>
      ) : isAuthorized ? (
        userData.defaultPasswordReset ? (
          <VICIDialContextProvider>
            <CallCenterContextProvider>
              <Routes>
                <Route path="/" element={<Aside />}>
                  <Route path="/" element={<MainHeader />}>
                    <Route path="/contact" element={<Contact />}>
                      <Route path="personal" element={<AllContactData />} />
                      <Route path="personal/all" element={<AllContactData />} />
                      <Route
                        path="personal/favorites"
                        element={<PersonalContactFavourites />}
                      />
                      <Route
                        path="personal/add"
                        element={<AddPersonalContact />}
                      >
                        <Route
                          path="detail"
                          element={<AddPersonalContactForm />}
                        />
                        <Route path="spouse" element={<SpouseDataFillUp />} />
                        <Route path="dependent" element={<DependentsForm />} />
                      </Route>

                      <Route
                        path="personal/edit"
                        element={<AddPersonalContact />}
                      >
                        <Route
                          path="detail"
                          element={<AddPersonalContactForm />}
                        />
                        <Route path="spouse" element={<SpouseDataFillUp />} />
                        <Route path="dependent" element={<DependentsForm />} />
                      </Route>
                      <Route
                        path="personal/details/:id"
                        element={<PersonalContactProfileData />}
                      />
                      <Route
                        path="spouse/details/:id"
                        element={<SpouseProfileData />}
                      />
                      <Route
                        path="dependent/details/:id"
                        element={<DependentsProfile />}
                      />

                      <Route
                        path="business"
                        element={<BusinessContactData />}
                      />
                      <Route
                        path="business/all"
                        element={<BusinessContactData />}
                      />
                      <Route
                        path="business/add"
                        element={<AddBusinessContact />}
                      />
                      <Route
                        path="business/favorites"
                        element={<BusinessContactFavourites />}
                      />
                      <Route
                        path="business/information"
                        element={<BusinessContactInformation />}
                      />
                      <Route
                        path="business/owner"
                        element={<BusinessOwner />}
                      />
                      <Route
                        path="business/manager"
                        element={<BusinessManager />}
                      />
                      <Route
                        path="business/employee"
                        element={<BusinessEmployee />}
                      />
                      <Route
                        path="business/executive"
                        element={<BusinessExecutive />}
                      />
                      <Route
                        path="business/human/resource"
                        element={<HumanResourcesData />}
                      />
                      <Route
                        path="business/details/:id"
                        element={<BusinessContactDetails />}
                      />
                      <Route path="lead/client" element={<ClientLead />} />
                      <Route
                        path="lead/campaigns/:campaignId"
                        element={<CampaignsLead />}
                      />
                      <Route
                        path="lead/campaigns/list"
                        element={<CampaignList />}
                      />
                      <Route
                        path="lead/prospects"
                        element={<ProspectsLead />}
                      />
                      <Route
                        path="lead/details/:id"
                        element={<PersonalContactProfileData />}
                      />

                      <Route path="quote/:type/:id" element={<Quote />} />

                      {/* <Route path="search" element={<SearchBased />} /> */}
                      <Route path="add/task/:id" element={<AddTask />} />
                      <Route path="list/tasks/:id" element={<ShowTask />} />
                      <Route path="edit/tasks/:id" element={<EditTask />} />
                      <Route path="add/note/:id" element={<AddNotes />} />
                      <Route path="list/notes/:id" element={<ShowNotes />} />
                      <Route path="edit/notes/:id" element={<EditNotes />} />
                      <Route
                        path="event/calendar/:id"
                        element={<EventCalendar />}
                      />
                      <Route path="add/event/:id" element={<CreateEvents />} />
                      <Route path="list/events/:id" element={<ListEvents />} />
                      <Route path="edit/events/:id" element={<EditEvents />} />
                    </Route>

                    <Route path="/addLeadsCall" element={<AddLeadsCall />} />
                    <Route path="call/centre" element={<CallCentre />} />
                    <Route path="phone/call" element={<PhoneCall />} />
                    <Route path="/hierarchy" element={<Hierarchy />} />
                    <Route path="/core/module" element={<CoreModule />} />
                    <Route path="/add/employee" element={<AddEmployee />} />
                    <Route path="/api/key" element={<APIKey />} />
                    <Route path="/custom/form" element={<CustomFrom />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/module" element={<Modules />} />
                    <Route path="/call/history/:id" element={<CallHistory />} />
                    <Route
                      path="/subscription/admin"
                      element={<SubscriptionAdmin />}
                    />
                    <Route
                      path="/subscription/company"
                      element={<SubscriptionCompany />}
                    />
                    <Route
                      path="/company/details/:id"
                      element={<SubscriptionDetail />}
                    />
                    <Route
                      path="/updateLeadForm/:id"
                      element={<UpdateLeadForm />}
                    />
                    <Route
                      path="/updateLeadForm/life/:id"
                      element={<UpdateLeadLifeForm />}
                    />
                    <Route
                      path="/profile/password/update"
                      element={<ProfilePasswordUpdate />}
                    />
                    <Route path="/profile" element={<UpdateProfile />} />
                    <Route
                      path="/company/profile"
                      element={<UpdateCompanyProfile />}
                    />
                    <Route
                      path="/admin/profile"
                      element={<UpdateAdminProfile />}
                    />
                  </Route>
                </Route>
              </Routes>
            </CallCenterContextProvider>
          </VICIDialContextProvider>
        ) : (
          <Routes>
            <Route
              path="/password-update"
              element={<ProfilePasswordUpdate />}
            />
          </Routes>
        )
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
        </Routes>
      )}
    </>
  );
};

export default Routing;
